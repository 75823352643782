
 <template>
  <div>
  <div
      class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
    >
      <div
        class="d-flex justify-content-center align-items-center flex-nowrap mb-1"
      >
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-group label-for="messes">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedMess"
              :options="messes"
              autoscroll
              :clearable="true"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 210px"
              placeholder="Select Mess"
            ></v-select>
          </b-form-group>
        </div>
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-group label-for="cities">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedCity"
              :options="cities"
              autoscroll
              :clearable="true"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 210px"
              placeholder="Select City"
            ></v-select>
          </b-form-group>
        </div>
        <!-- <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-form-group label-for="managinghq">
            <v-select
              inputId="id"
              label="name"
              v-model="selectedmanaginghq"
              :options="managinghq"
              autoscroll
              :clearable="true"
              class="bg-white mt-1"
              style="border-radius: 0.357rem; width: 210px"
              placeholder="Select Managing HQ"
            ></v-select>
          </b-form-group>
        </div> -->
        <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-5"
        >
          <b-input-group>
            <b-form-input
              id="checkinDateInput"
              v-model="checkinDateFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="checkinDate"
                v-model="checkinDateFilter"
                button-variant="primary"
                button-only
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>
          </div>
            <div
          class="d-flex justify-content-center align-items-center flex-nowrap mr-50"
        >
          <div
            class="d-flex justify-content-center align-items-center flex-nowrap mr-50"
          >
          <b-input-group>
            <b-form-input
              id="checkinOutInput"
              v-model="checkoutDateFilter"
              type="text"
              placeholder="YYYY-MM-DD"
              autocomplete="on"
            ></b-form-input>
            <b-input-group-append>
              <b-form-datepicker
                id="checkinDate"
                v-model="checkoutDateFilter"
                button-variant="primary"
                button-only
                variant="primary"
                style="height: 38px"
              />
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>
        </div>
    </div>
         <div
        class="d-flex justify-content-center align-items-center flex-nowrap mb-2"
      >
        <b-button variant="primary" pill @click="search">
          <feather-icon icon="SearchIcon" class="mr-50" />
          <span class="align-middle">Search</span>
        </b-button>
      </div>
    <b-row>
      <b-col md="6">
        <BookingWrtStatuses :dataList="dataList" />
      </b-col>

      <b-col md="6">
        <BookingWrtUserTypes :dataList="dataList" />
      </b-col>
    </b-row>
    <b-row>
      <b-col md="6">
        <RoomTypesBookingsVue :dataList="dataList" />
      </b-col>
      <b-col md="6">
        <BookingsGroupDataVue :dataList="dataList" />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="12">
        <BookingDetailsAgainstMessVue :dataList="dataList.bookings_by_mess"/>
      </b-col>
     </b-row>
  </div>
</template>
<script>
import BookingDetailsAgainstMessVue from "./BookingDetailsAgainstMess.vue";
import BookingsGroupDataVue from "./BookingsGroupData.vue";
import BookingWrtStatuses from "./BookingWrtStatuses.vue";
import BookingWrtUserTypes from "./BookingWrtUserTypes.vue";
import RoomTypesBookingsVue from "./RoomTypesBookings.vue";
import { mapActions, mapGetters } from "vuex";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { $themeColors } from "@themeConfig";
import flatPickr from "vue-flatpickr-component";
import util from "@/util.js";

export default {
  components: {
    flatPickr,
    ValidationProvider,
    ValidationObserver,
    BookingWrtStatuses,
    BookingWrtUserTypes,
    BookingsGroupDataVue,
    RoomTypesBookingsVue,
    BookingDetailsAgainstMessVue,
  },
  mixins: [util],
  data() {
    return {
      required:'',
      cities: [],
      selectedCity: null,
      managinghq: [],
      selectedmanaginghq: null,
      visible: false,
      messNameFilter: "",
      // hotelNameFilter: "",
      cityNameFilter: "",
      managingHqNameFilter: "",
      isBusy: false,
      allowEdit: true,
      checkinDateFilter: "",
      checkoutDateFilter: "",
      messes: [],
      selectedMess: null,
      data: [],
      dataList: {},
      statistic: [],
    };
  },
  created() {},
  async mounted() {
    await this.setCities();
    await this.setManagingHqs();
    await this.setMesses();
    await this.search();
  },
  methods: {
    ...mapActions({
      getBookingCitiesUnpaginated: "appData/getBookingCitiesUnpaginated",
      getManagingHqsUnpaginated: "appData/getManagingHqsUnpaginated",
      getMessesUnpaginated: "appData/getMessesUnpaginated",
      getAgmDashboardData: "appData/getAgmDashboardData",
    }),
    async setCities() {
      let res = null;
      try {
        res = await this.getBookingCitiesUnpaginated({
          mess_only: true,
        });
        if (res.status === 200) {
          this.cities = res.data;
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async setManagingHqs() {
      let res = null;
      try {
        res = await this.getManagingHqsUnpaginated();
        if (res.status === 200) {
          this.managinghq = res.data;
        }
      } catch (error) {
        this.displayError(error);
      }
    },
    async setMesses() {
      let res = null;
      try {
        res = await this.getMessesUnpaginated({});
        if (res.status === 200) {
          this.messes = res.data;
        }
      } catch (error) {
        this.displayError(error);
      }
    },

    async search() {
      let filters = { }
        if(this.checkinDateFilter){
          filters['date_from'] = this.checkinDateFilter
        }
        if(this.checkoutDateFilter){
          filters['date_to'] = this.checkoutDateFilter
        }
        if(this.selectedCity){
          filters['city'] = this.selectedCity.id
        }
        if(this.selectedmanaginghq){
          filters['managing_hq'] = this.selectedmanaginghq.id
        }
        if(this.selectedMess){
          filters['mess'] = this.selectedMess.id
        }
        let res = await this.getAgmDashboardData(filters);

        if (res.status === 200) {
          this.dataList = res.data;
        }
      },
  },
  computed: {
    ...mapGetters({
      hasPermission: "appData/hasPermission",
      getLoggedInUser: "appData/getUser",
    }),
  },

  watch: {},
};
</script>

<style>
.bg-image {
  background-image: url("~@/assets/images/newDashboard/Background-1.png");
  background-size: cover;
  background-position: center;
}
.shadow {
  box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
  -webkit-box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
  -moz-box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
}
</style>
