<template>
  <b-card no-body>
    <b-card-header>
      <b-card-title>Booked Rooms by Room Type</b-card-title>
    </b-card-header>
    <b-card-body>
      <vue-apex-charts type="pie" height="180" class="mt-2 " :options="customersPie.chartOptions" :series="customersPie.series" />
      <div class="pt-25">
        <div v-for="(data, index) in chartData.listData" :key="index" class="d-flex justify-content-between mb-1">
          <div class="series-info">
            <span class="font-weight-bolder">{{ data.text }}</span>
          </div>
          <span>{{ data.result }}</span>
        </div>
      </div>
    </b-card-body>
  </b-card>
</template>
<script>
import {
  BCard, BCardHeader, BCardTitle, BCardBody,
} from 'bootstrap-vue'
import VueApexCharts from 'vue-apexcharts'

export default {
  components: {
    BCard,
    BCardHeader,
    BCardTitle,
    BCardBody,
    VueApexCharts,
  },
  props: {
    dataList: Object,
  },
  data() {
    return {
      chartData: {
        listData: [],
      },
      customersPie: {
        series: [],
        chartOptions: {
          chart: {
            toolbar: { show: false },
          },
          labels: ['Standard', 'One Room Suite', 'Two Room Suite'],
          dataLabels: { enabled: false },
          legend: { show: true },
          stroke: { width: 4 },
          // colors: ['#7367F0', '#FF9F43', '#EA5455'], 
        },
      },
    }
  },
  watch: {
    dataList: {
      handler: 'updateChartData',
      deep: true,
      immediate: true,
    },
  },
  methods: {
    updateChartData() {
      if (this.dataList && this.dataList.room_types) {
        const roomTypes = Object.keys(this.dataList.room_types);
        const counts = Object.values(this.dataList.room_types);

        // Update chart
        this.customersPie.chartOptions.labels = roomTypes;
        this.customersPie.series = counts;

        // Update list
        this.chartData.listData = roomTypes.map((type, index) => ({
          text: type,
          result: counts[index],
        }));
      }
    },
  },
}
</script>
