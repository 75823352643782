<template>
  <div>
    <b-card class="bg-image shadow border-0">
      <b-row>
        <b-col md="6">
          <h3 style="font-weight: bold">Bookings w.r.t User Types</h3>
          <br />
          <ul>
            <li class="first">Serving</li>
            <label>{{ servingCount }}</label>
            <li class="second">Retired</li>
            <label>{{ retiredCount }}</label>
            <li class="third">Civilian</li>
            <label>{{ civilianCount }}</label>
          </ul>
        </b-col>
        <b-col md="6">
          <vue-apex-charts class="mt-5"
            type="donut"
            v-if="dataList && Object.keys(dataList).length > 0"
            :key="chartKey"
            height="500"
            :options="donutChart.chartOptions"
            :series="donutChart.series"
          />
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import {
  BCard,
  BCardBody,
  BCardHeader,
  BCardTitle,
  BCardSubTitle,
  BBadge,
} from "bootstrap-vue";
import VueApexCharts from "vue-apexcharts";
import { $themeColors } from "@themeConfig";
import { mapActions, mapGetters } from "vuex";
import util from "@/util.js";

export default {
  components: {
    VueApexCharts,
    BCardHeader,
    BCard,
    BBadge,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    dataList: Object,
  },
  mixins: [util],
  data() {
    return {
      donutChart: {
        series: [],
        chartOptions: {
          legend: {
            show: true,
            position: "bottom",
            fontSize: "14px",
            fontFamily: "Montserrat",
          },
          dataLabels: {
            enabled: false,
            formatter(val) {
              // eslint-disable-next-line radix
              return `${parseInt(val)}`;
            },
          },
          plotOptions: {
            pie: {
              donut: {
                labels: {
                  show: true,
                  name: {
                    fontSize: "2rem",
                    fontFamily: "Montserrat",
                  },
                  value: {
                    fontSize: "1rem",
                    fontFamily: "Montserrat",
                    formatter(val) {
                      // eslint-disable-next-line radix
                      return `${parseInt(val)}`;
                    },
                  },
                  total: {
                    show: true,
                    fontSize: "1.5rem",
                    label: "Total",
                    formatter(series) {
                      const total = series.config.series.reduce(
                        (acc, val) => acc + val,
                        0
                      );
                      return total.toString();
                    },
                  },
                },
              },
            },
          },
          labels: ["Serving", "Retired", "Civilian"],
          responsive: [
            {
              breakpoint: 992,
              options: {
                chart: {
                  height: 380,
                },
                legend: {
                  position: "bottom",
                },
              },
            },
            {
              breakpoint: 576,
              options: {
                chart: {
                  height: 320,
                },
                plotOptions: {
                  pie: {
                    donut: {
                      labels: {
                        show: true,
                        name: {
                          fontSize: "1.5rem",
                        },
                        value: {
                          fontSize: "1rem",
                        },
                        total: {
                          fontSize: "1.5rem",
                        },
                      },
                    },
                  },
                },
                legend: {
                  show: true,
                },
              },
            },
          ],
        },
      },
    };
  },
  methods: {
    updateChart() {
      this.chartKey++;
      if (this.dataList) {
        this.donutChart.series = [
          this.dataList.serving,
          this.dataList.retired,
          this.dataList.civilian,
        ];
      }
    },
  },
  computed: {
    servingCount() {
      return this.dataList ? this.dataList.serving : 0;
    },
    retiredCount() {
      return this.dataList ? this.dataList.retired : 0;
    },
    civilianCount() {
      return this.dataList ? this.dataList.civilian : 0;
    },
  },
  watch: {
    dataList: {
      deep: true,
      immediate: true,
      handler(newVal) {
        this.updateChart();
        this.chartKey++;
      },
    },
  },
};
</script>
<style scoped>
.bg-image {
  background-image: url("~@/assets/images/newDashboard/Background-1.png");
  background-size: cover;
  background-position: center;
}
.shadow {
  box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
  -webkit-box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
  -moz-box-shadow: 8px 8px 44px -9px rgba(0, 0, 0, 0.35) !important;
}
.first::marker {
  color: rgb(14, 159, 217);
  font-size: 25px;
}
.second::marker {
  color: rgb(233, 116, 116);
  font-size: 25px;
}
.third::marker {
  color: rgb(85, 205, 162);
  font-size: 25px;
}

li {
  font-size: 18px;
  margin-top: 10px;
}
label {
  font-size: 18px;
  font-weight: bolder;
  color: black;
}
</style>