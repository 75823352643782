<template>
  <b-card
    no-body
    class="card-company-table"
  >
    <b-table
      :items="dataList"
      responsive
      :fields="fields"
      class="mb-0"
    >
      <template #cell(mess_name)="data">
        {{ data.value }}
      </template>

      <template #cell(city)="data">
        {{ data.value }}
      </template>

      <template #cell(managing_hq)="data">
        {{ data.value }}
      </template>

      <template #cell(bookings)="data">
        {{ data.value }}
      </template>

      <template #cell(revenue)="data">
        {{ 'PKR '+data.value }}
      </template>
    </b-table>
  </b-card>
</template>

<script>
import {
  BCard, BTable, BAvatar, BImg,
} from 'bootstrap-vue'
import { mounted } from 'vue-echarts'

export default {
  components: {
    BCard,
    BTable,
    BAvatar,
    BImg,
  },
//     props: {
//     tableData: {
//       type: Array,
//       default: () => [],
//     },
//   },
  props: {
    dataList: Array,
  },

  data() {
    return {
       fields: [
        { key: 'mess_name', label: 'Mess Name' },
        { key: 'city', label: 'City' },
        { key: 'managing_hq', label: 'Managing HQ' },
        { key: 'bookings', label: 'Bookings' },
        { key: 'revenue', label: 'Revenue' }
      ]
    }
  },
mounted(){
}
}
</script>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';
@import '~@core/scss/base/components/variables-dark';

.card-company-table ::v-deep td .b-avatar.badge-light-company {
  .dark-layout & {
    background: $theme-dark-body-bg !important;
  }
}
</style>
